import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import TaxServicesIllustration from "../../../svg/ComparisonResultPageIllustrations/personaltaxservice.svg";
import HRBlockSVG from "../../../svg/ComparisonResultGridLogos/personaltaxservices/handrblock.svg";
import TurboTaxSVG from "../../../svg/ComparisonResultGridLogos/personaltaxservices/turbotax.svg";
import TaxSlayerSVG from "../../../svg/ComparisonResultGridLogos/personaltaxservices/taxslayer.svg";
import TaxActSVG from "../../../svg/ComparisonResultGridLogos/personaltaxservices/taxact.svg";
export const pageMetaData = {
  pageTitle: "Compare Personal Tax Services",
  pageDescription: "File With Confidence From $0",
  pageImagePath: "/homecleaningservices.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Price"
      }, {
        index: 2,
        text: "Product Name"
      }, {
        index: 3,
        text: "Ease of Use"
      }, {
        index: 4,
        text: "Suitable For"
      }, {
        index: 5,
        text: "Support"
      }, {
        index: 6,
        text: "Supported Features"
      }, {
        index: 7,
        text: "Drawbacks"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "H & R Block",
          subText: "",
          imageKey: "hr-block"
        }
      }, {
        index: 2,
        text: "0",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 5
      }, {
        index: 3,
        text: "H & R Block Free"
      }, {
        index: 4,
        text: "7/10"
      }, {
        index: 5,
        text: "Simple Tax Returns"
      }, {
        index: 6,
        text: "Video, Knoweldge Base"
      }, {
        index: 7,
        text: "Supported Features",
        usePopover: true,
        popOverTitle: "Supported Features",
        popOverContents: "This option allows you to file a 1040 plus schedules 1, 2 and 3, which makes it usable by a lot more people than most other free software packages.|Income from wages (W-2), income from bank interest (1099-INT) and dividends (1099-DIV), student loan interest (1098-E)."
      }, {
        index: 8,
        text: "Drawbacks",
        usePopover: true,
        popOverTitle: "Drawbacks",
        popOverContents: "Unable to handle itemized deductions (Schedule A), Deductions and expenses from freelance or self-employed work (Schedule C), HSA distributions (1099-SA)."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.hrblock.com/online-tax-filing/free-online-tax-filing/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up with H & R Block Free",
        subText: "",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "H & R Block",
          subText: "",
          imageKey: "hr-block"
        }
      }, {
        index: 2,
        text: "49.99",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "H & R Block Deluxe"
      }, {
        index: 4,
        text: "7/10"
      }, {
        index: 5,
        text: "Itemized Returns"
      }, {
        index: 6,
        text: "Video, Knowledge Base"
      }, {
        index: 7,
        text: "Supported Features",
        usePopover: true,
        popOverTitle: "Supported Features",
        popOverContents: "This version lets you itemize and claim several tax deductions and tax credits, but if you’re an investor, landlord or filing a Schedule C (for freelancers or small business owners), you’ll need to upgrade.|All H&R Block Free can, plus itemized deductions (Schedule A), HSA distributions (1099-SA)."
      }, {
        index: 8,
        text: "Drawbacks",
        usePopover: true,
        popOverTitle: "Drawbacks",
        popOverContents: "Unable to handle deductions and expenses from freelance or self-employed work (Schedule C), capital gains and losses (Schedule D), income from rental property (Schedule E)."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.hrblock.com/tax-software/deluxe-tax-software/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to H & R Blck Delux",
        subText: "",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "TurboTax",
          subText: "",
          imageKey: "turbo-tax"
        }
      }, {
        index: 2,
        text: "60",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 2
      }, {
        index: 3,
        text: "TurboTax Delux"
      }, {
        index: 4,
        text: "9/10"
      }, {
        index: 5,
        text: "Complex Returns"
      }, {
        index: 6,
        text: "Video, Online FAQs"
      }, {
        index: 7,
        text: "Supported Features",
        usePopover: true,
        popOverTitle: "Supported Features",
        popOverContents: "This version lets you itemize and claim several other tax deductions and tax credits. You can file a Schedule C for business income (but not expenses). You can’t report capital gains or rental income (Schedules D and E)."
      }, {
        index: 8,
        text: "Drawbacks",
        usePopover: true,
        popOverTitle: "Drawbacks",
        popOverContents: "This version does not have the capability for reporting investments and rental income"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://turbotax.intuit.com/personal-taxes/online/?priorityCode=6099000013&PID=8195553&SID=7f90966d9c604dc79798d3a9e46f09d5&cid=all_cjtto-8195553_int&ref_id=4a62f740e86c11ea821e007a0a1c0e0d_236204174173460989%3AWV9PKjDwA79b",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up with Turbo Tax",
        subText: "",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "TaxSlayer",
          subText: "",
          imageKey: "tax-slayer"
        }
      }, {
        index: 2,
        text: "17",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "Federal"
      }, {
        index: 4,
        text: "6/10"
      }, {
        index: 5,
        text: "Budget Users"
      }, {
        index: 6,
        text: "Phone, Email"
      }, {
        index: 7,
        text: "Online Payment",
        usePopover: true,
        popOverTitle: "Supported Features",
        popOverContents: "This package works for virtually everybody: itemizers, landlords, freelancers and so on, because TaxSlayer’s paid packages vary by support offered rather than by tax situation. It imports W-2s to get you going.|TaxSlayer’s interface is as handsome as other, more expensive versions on the market. You can skip around if you need to (or even create your own list of specific tax forms you want to fill out), and a banner along the side keeps track of where you are in the process."
      }, {
        index: 8,
        text: "Drawbacks",
        usePopover: true,
        popOverTitle: "Drawbacks",
        popOverContents: "TaxSlayer’s paid packages don’t have the ability to import photos of W-2s (though you can import the document online or upload a PDF), and there’s no tool to help you calculate the deduction value of items you donate to charity.|TaxSlayer doesn’t import 1099s."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.taxslayer.com/?utm_source=affiliate&utm_medium=cj&utm_term=13840657&utm_campaign=New+Imagitas&cjevent=32f46c05e86c11ea815400860a1c0e10&c3ch=Affiliate&c3nid=5226984",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up with TaxSlayer",
        subText: "",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "TaxAct",
          subText: "",
          imageKey: "tax-act"
        }
      }, {
        index: 2,
        text: "54.95",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "Federal"
      }, {
        index: 4,
        text: "9/10"
      }, {
        index: 5,
        text: "Value For Money"
      }, {
        index: 6,
        text: "Phone, FAQs, Chat"
      }, {
        index: 7,
        text: "Supported Features",
        usePopover: true,
        popOverTitle: "Supported Features",
        popOverContents: "This version lets you itemize and claim several other tax deductions and tax credits. It also comes with unlimited email or phone support from tax specialists. If you’re an investor, a landlord or filing a Schedule C (for freelancers or small-business owners), you’ll need to upgrade.|Generally less expensive than similar TurboTax and H&R Block options.|Human support available, though not always as robust as some competitors.|Audit coverage costs extra."
      }, {
        index: 8,
        text: "None"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.taxact.com/?sc=18097655555&promo=&ad=3842381&cjevent=c6226234e86611ea815d007e0a1c0e11&segid=&utm_source=CJ&utm_medium=Affiliate&utm_campaign=180976555&dclid=CjgKEAjws536BRDptIGAxsKpzU8SJADOEELSNsKLXMNV6-Kf6QULlgwLoKqWBePL7yimHMxTVXGxQvD_BwE",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to TaxAct",
        subText: "",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare personal tax services`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<TaxServicesIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="TaxServicesIllustration" />, <TaxServicesIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="TaxServicesIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`When tax season hits, millions of Americans feel the same sense of dread. Doing your own taxes, after all, isn't always an easy thing because the forms can be confusing, and it's easy to overlook deductions that could save you money on the taxes you may owe. Fortunately, you are not alone, and there are many programs that can help you file your taxes quickly and correctly. Check out the top options to compare tax services and find out which one is best for you.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Home Cleaning Services" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <HRBlockSVG className="w-full" imagekey="hr-block" mdxType="HRBlockSVG" />
  <TurboTaxSVG className="w-full" imagekey="turbo-tax" mdxType="TurboTaxSVG" />
  <TaxSlayerSVG className="w-full" imagekey="tax-slayer" mdxType="TaxSlayerSVG" />
  <TaxActSVG className="w-full" imagekey="tax-act" mdxType="TaxActSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="financial" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked and Compared Tax Services`}</h2>
        <h3>{`Free or Fee`}</h3>
        <p>{`The majority of personal tax preparation services have a free option for qualifying earners. However, the detail we paid most attention to was whether the federal tax return could be filed for free. This is because all taxpayers have to file federal returns, while only some taxpayers do not have to file state taxes at all, either because of their income or because their state does not require it.`}</p>
        <p>{`We also looked at the price of filing fees. For example, one of the most affordable programs for most income earners was TaxSlayer for federal returns.`}</p>
        <h3>{`Income Restrictions`}</h3>
        <p>{`Income restrictions generally determine what kind of tax filing program you qualify for. The IRS Free Filing tax programs, for example, all have maximum annual income limits. The most popular options are TaxAct, which has a $36,000 limit, and H&R Block, which has a $69,000 limit. Your annual gross income will determine whether you need to pay to file your federal taxes at all or whether you only need to pay to file state returns.`}</p>
        <h3>{`Age Restrictions`}</h3>
        <p>{`Age restrictions are also a determining factor when deciding which tax filing program you are qualified for. While the majority of tax preparation services do not have age restrictions, there are some free filing editions that require taxpayers to be under a certain age. FileYourTaxes, for example, requires users to be younger than 65 years old, which may disqualify you from using that program altogether.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="personal tax services" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Allow `}<a parentName="p" {...{
            "href": "/"
          }}>{`The Comparison Company`}</a>{` to help you get a head start on tax season and compare tax services now to find which program you qualify for and which one will serve your tax filing needs the best.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>


    <p>{`;`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      